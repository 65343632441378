@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Belleza&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Petit+Formal+Script&display=swap");

$brandBeige: #cedbb0;
$brandBlack: #000000;
$brandForestGreen: #054a29;
$brandLightGreen: #79f6ba;
$brandDarkBlue: #092a48;
$brandLightBlue: #bbdcdf;
$brandOliveGreen: #7fa67a;
$brandLightOliveGreen: #b8ceb6;
$brandOrange: #df5a01;
$brandLightOrange: #fe9d5d;
$brandWhite: #ffffff;

$brandPrimaryColor: $brandOliveGreen;
$brandPrimaryColorActive: $brandLightOliveGreen;
$brandSecondaryColor: $brandDarkBlue;
$brandSecondaryColorActive: $brandLightBlue;

$brandPrimaryCtaColor: $brandOrange;
$brandPrimaryCtaColorActive: $brandLightOrange;
$brandSecondaryCtaColor: $brandDarkBlue;
$brandSecondaryCtaColorActive: $brandLightBlue;
$brandLinkColor: $brandSecondaryColor;
$brandLinkColorActive: $brandSecondaryColorActive;

$brandPrimaryFontFamily: Raleway;
$brandSecondaryFontFamily: Belleza;
$brandPrimaryFontColor: $brandBlack;
$brandSecondaryFontColor: $brandWhite;

$brandHeroImage: url("images/heroBeachAtSunrise2.jpg");

// Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

html, body {
  background-color: $brandBeige;
  font-family: $brandPrimaryFontFamily;
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.App {
  flex: 1;
  margin: 86px 0 0 0;
  text-align: center;
}

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
//   color: wheat;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }


a {
  color: $brandLinkColor;

  &:hover {
    color: $brandLinkColorActive;
    text-decoration: none;
  }
}


.navbar {
  padding: 0;

  .header {
    background-color: $brandPrimaryColor;
    color: $brandSecondaryFontColor;
    display: flex;
    width: 100%;

    p {
      font-family: Petit Formal Script;
      position: relative;
      top: 0.5em;
    }
  }

  .navbarFull {
    background-color: $brandDarkBlue;
    padding: 0.25em 0;
    width: 100%;
  }

  .navbar-brand {
    display: inline-block;
    left: 2.5em;
    position: fixed;
    top: 1.9em;

    p {
      color: $brandSecondaryFontColor; //$brandLightBlue;
      margin-left: 0.25em;
      position: relative;
      top: 0.25em;
    }

    svg {
      height: 1.5em;
      position: relative;
      top: 0.2em;
    }
  }

  .navbar-nav {
    background-color: $brandWhite;
    padding: 0 2em 1em 2em;
    width: 275px;

    .nav-link {
      color: $brandPrimaryFontColor;
      //font-weight: bold;
    }
  }

  .navbar-toggler {
    border: 0;
    color: $brandLightBlue;
    margin-left: 0.5em;
    margin-top: 0.25em;
  }

  .btn {
    background-color: $brandWhite;
    border-color: $brandWhite;
    color: $brandPrimaryFontColor;
    margin-right: 0.5em;

    &:hover {
      background-color: $brandSecondaryCtaColorActive;
      border-color: $brandSecondaryCtaColorActive;
    }
  }
}

.jumbotron {
  border-radius: 0;
  height: 25em;
  margin-bottom: 2rem;
  padding: 1rem;
  position: relative; 

  &::before {
    background-image: $brandHeroImage;
    background-size: cover;
    border-radius: 0;
    bottom: 0px;
    content: "";
    left: 0px;
    opacity: 0.6;
    position: absolute;
    right: 0px;
    top: 0px;
  }

  h1 {
    color: $brandDarkBlue;
    font-family: $brandSecondaryFontFamily;
    font-size: 3.5em;
    position: relative;
  }

  p {
    color: $brandDarkBlue;
    font-size: x-large;
    font-weight: bold;
    position: relative;
  }

  .btn {
    position: relative;
    top: -1.25em;
  }
}

.carousel {
  .carousel-control-next,
  .carousel-control-prev {
    visibility: hidden;
  }

  .carousel-indicators {
    bottom: -2.25em;

    button {
      background-color: $brandWhite;
      border-radius: 50%;
      height: 0.75em;
      width: 0.75em;

      &.active {
        background-color: $brandDarkBlue;
      }
    }
  }

  .carousel-item {
    transition: opacity 1s ease-in-out !important;
  }
}

.homeContent {
  position: relative;
  top: -5em;

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 2rem;
  }

  .justify-content-center {
    margin: 0 1em;
  }

  .fullWidth {
    background-color: $brandPrimaryColor;
    color: $brandWhite;
    margin: 0;
    padding: 2em 1em;
  }
}

.pageContent {
  margin-top: 1em;
  position: relative;

  &.noTop {
    margin-top: 0;
  }

  .margin {
    margin: 0 2em;
  }

  .narrowMargin {
    margin: 0 2em 2em 2em;
  }

  p {
    padding-left: 1em;
    text-align: left;
    text-indent: -1em;

    &.center {
      padding-left: 0;
      text-align: center;
      text-indent: 0;
    }
  }

  img {
    height: auto;
    margin-bottom: 2em;
    max-width: 25em;
    width: 100%;

    &.vertical {
      height: 100%;
      max-height: 15em;
      width: auto;
    }
  }

  li {
    margin-bottom: 0.5em;
    text-align: left;
  }

  .contact {
    color: $brandSecondaryColor;

    a {
      color: $brandSecondaryColor;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    p {
      color: $brandSecondaryColor;
      font-weight: bold;
      margin-bottom: 1.75em;
    }
  }

  .accordion {
    margin-bottom: 2em;
    text-align: left;

    .accordion-button {
      background-color: $brandSecondaryCtaColorActive;
      box-shadow: none;
      color: $brandPrimaryFontColor;
      cursor: pointer;
      font-weight: bold;

      &::after {
        background: none;
      }
    }
  }

  .fontColor {
    color: $brandSecondaryColor;
  }

  .subtitle {
    font-size:x-large;
    text-decoration: underline;
  }

  .quote {
    background-color: $brandOliveGreen;
    color: $brandWhite;
    margin-top: 2em;
    padding: 2em 1em;

    p {
      font-style: italic;
      margin-bottom: 0;
    }

    .quoteReference {
      font-style: normal;
      margin-bottom: 0;
    }

    .empty {
      flex-grow: 1;
    }
  }

  .bookEmbed {
    height: 1300px;
    min-width: 250px;
    overflow-y: hidden !important;

    [class*="FooterContainer"] {
      display: none !important;
    }
  }

  .contentPost {
    margin-bottom: 5em;

    .title {
      background-color: $brandOliveGreen;
      color: $brandWhite;
      font-size: x-large;
      height: 4em;
      width: 100%;

      .wrapper {
        width: 100%;
      }
    }

    .subTitle {
      background-color: $brandDarkBlue;
      color: $brandWhite;
      margin-bottom: 1em;

      span {
        margin-left: 1em;
      }
    }

    .content {
      margin: 0 1em;

      a {
        color: $brandPrimaryCtaColor;
        text-decoration: underline;

        &:hover {
          color: $brandPrimaryCtaColorActive;
        }
      }
    }

    .embeddedAsset {
      height: auto !important;
      margin-bottom: 1em;
      width: auto !important;
    }
  }
}

.breadcrumb {
  background-color: unset;
  color: $brandSecondaryColor;
  display: flex;
  font-size: smaller;
  font-style: italic;
  margin: 2.5em 0 2em 0;
  padding: 0;

  a {
    color: $brandPrimaryColor !important;
    display: inline-block;
    font-weight: bold;
    height: 100%;
    text-decoration: none !important;

    &:hover {
      color: $brandPrimaryColor !important;
      text-decoration: underline !important;
    }

    &.logo {
      height: 2em;
      position: relative;
      top: -0.5em;
    }
  }

  p {
    display: inline-block;
    font-weight: bold;
    margin: 0;
    padding: 0 0.5em 0 0;
    text-indent: 0;
  }

  svg {
    height: 2em;
  }
}

.btn {
  background-color: $brandPrimaryCtaColor;
  border-color: $brandPrimaryCtaColor;
  color: $brandSecondaryFontColor;

  &:hover {
    background-color: $brandPrimaryCtaColorActive;
    border-color: $brandPrimaryCtaColorActive;
  }
}

.btnEmail {
  background-color: $brandSecondaryCtaColor;
  border-color: $brandSecondaryCtaColor;
  color: $brandSecondaryFontColor;

  &:hover {
    background-color: $brandSecondaryCtaColorActive;
    border-color: $brandSecondaryCtaColorActive;
    color: $brandBlack;
  }
}

.btnContent {
  background-color: $brandSecondaryCtaColor;
  border-color: $brandSecondaryCtaColor;
  border-radius: 4px;
  color: $brandPrimaryColor;
  margin: 0 1em 3em 1em;
  margin-left: 1em;
  padding: 0.5em 1em;

  &:hover {
    color: $brandPrimaryColorActive;
  }
}

.cta {
  background-color: $brandWhite;
  border: 2px solid $brandDarkBlue;
  border-radius: 0.5em;
  margin: 1em;
  padding: 0.75em;

  p {
    font-size: x-large;
    font-weight: bold;
    margin-bottom: 0;
  }
}

.ctaImage {
  img {
    height: auto;
    margin-bottom: 1.5em;
    width: 100%;
  }

  &.hideSmall {
    display: none;
  }
  
  &.hideLarge {
    display: block;
  }
}

.ctaSteps {
  margin-bottom:  3em;

  img {
    height: 5em;
    margin-bottom: 2em;
  }

  .heading {
    font-size: large;
    font-weight: bold;
    margin-bottom: 0;
  }
}

.ctaService {
  display: flex;
  height: 100px;
  margin: 1em 0;

  .title {
    display: inline-block;
    font-size: x-large;
    font-weight: bold;
    text-decoration: none;
    width: 100%;
  }

  .fullLink {
    width: 100%;
  }
}

.ctaServiceLarge {
  height: 130px;
}

.ctaTextOnly {
  padding: 0;

  h4 {
    font-weight: bold;
    margin: 3em 0;
  }

  p {
    font-size: large;
    margin-bottom: 0;

    &.fontLarger {
      font-size: larger;
    }
  }
}

.testimonial {
  background-color: $brandPrimaryColorActive;
  border: 1px solid $brandPrimaryColorActive;
  border-radius: 2em;
  max-width: 25em;
  margin: 0 auto;
  padding: 2em 3em 0 3em;

  .blockquote-footer {
    margin-top: 1em;
  }

  .pointer {
    background-color: $brandPrimaryColorActive;
    border-radius: 0 0 0.25em 0;
    bottom: -18px;
    height: 35px;
    left: calc(0.5vw - 75px);
    margin: 0 auto;
    position: relative;
    transform: rotate(45deg);
    width: 35px;
  }

  p {
    font-size: medium !important;
    font-style: italic;
  }

  p:before {
    content: open-quote;
  }

  p:after {
    content: close-quote;
  }
}

.footerNav {
  background-color: $brandDarkBlue;
  font-size: 0.75em;
  overflow: hidden;
  width: 100%;

  .logoLink {
    display: inline-block;
    left: 1em;
    position: relative;
  }

  .row {
    align-items: center;
  }

  .wrappedText {
    display: inline-block;
    line-height: 1.1em;
  }

  .text-center {
    padding: 0.5em;
  }

  .nav-link {
    padding: 0;
  }

  a {
    color: $brandPrimaryColor;

    &:hover {
      color: $brandPrimaryColorActive;
    }
  }

  svg {
    height: 5em;
    width: 5em;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
  .jumbotron {
    h1 {
      font-size: 4em;
    }

    p {
      font-size: xx-large;
    }
  }

  .homeContent {
    .justify-content-center {
      margin: 0 3em;
    }

    .fullWidth {
      margin: 0;
      padding: 3em;
    }
  }

  .pageContent {
    .margin {
      margin: 1em 5em 2em 5em;
    }

    .quote {
      padding: 2em 5em;
    }

    .narrowMargin {
      margin: 1em 2em 2em 2em;
    }

    .embeddedAsset {
      &.bookCover {
        float: left;
        margin-right: 1.5em;

        & + .contentParagraph {
          min-height: 200px;
        }
      }
    }
  }

  .ctaService {
    margin: 0.5em;
  }

  .ctaTextOnly {
    p {
      font-size: x-large;
    }
  }

  .testimonial {
    max-width: 35em;

    .pointer {
      left: calc(0.5vw - 150px);
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
  .jumbotron {
    h1 {
      margin-top: 1.75em;
    }
  }

  .homeContent {
    .justify-content-center {
      margin: 0 5em;
    }

    .fullWidth {
      margin: 0;
      padding: em;
    }
  }

  .ctaImage.hideSmall {
    display: block;
  }

  .ctaImage.hideLarge {
    display: none;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
  .pageContent {
    .contentPost {
      .subTitle {
        span {
          margin-left: 3em;
        }
      }

      .content {
        margin: 0 3em;
      }
    }
  }

  .testimonial {
    max-width: 50em;

    .pointer {
      left: calc(0.5vw - 250px);
    }
  }

  .btnContent {
    margin: 0em 3em 3em 3em;
  }
}

// Extra large devices (large desktops, 1200px and up)
//@media (min-width: 1200px) { ... }